import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router/index'
import store from './store/index'
import './common/style/reset.css'
import './common/style/base.scss'
import websocket from 'vue-native-websocket';
import 'tailwindcss/tailwind.css'
import '@/common/css/tailwind.css'

Vue.use(websocket, '', {
  connectManually: true, // 手动连接
  format: 'json', // json格式
  // reconnection: true, // 是否自动重连
  // reconnectionAttempts: 5, // 自动重连次数
  // reconnectionDelay: 2000, // 重连间隔时间
});

Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  websocket,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
