import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/pages/home/index-page.vue"),
    redirect: "/home",
    children: [
      { path: "/home", component: () => import("@/pages/home/home-page.vue") },
      { path: "/chat", component: () => import("@/pages/home/chat-page.vue") },
      { path: "/chat/:chat_code", component: () => import("@/pages/home/chat-page.vue") },
      { path: "/chat-list", component: () => import("@/pages/home/chat-list-page.vue") },
    ],
  },
];

const router = new VueRouter({
  routes,
  allowDuplicateParams: true,
});

export default router;
