import Vue from 'vue'
import Vuex from 'vuex'
import chat from './modules/chat'
import header from './modules/header'

Vue.use(Vuex)

export default new Vuex.Store({
  // state: {
  //   count: 0
  // },
  // getters: {

  // },
  // mutations: {

  // },
  // actions: {

  // },
  modules: {
    chat,
    header,
  }
})
